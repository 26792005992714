import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/index.js";
import Table from "../Table/index.js";
const Offers = () => {
  const [isLoading, setIsLoading] = useState();
  const [listVal, setListVal] = useState();
  const [addNew, setAddNew] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [list, setList] = useState();
  const [body_1, setBody_1] = useState([]);
  const [body, setBody] = useState([]);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [offerImg, setOfferImg] = useState();
  const [Offerurl, setOfferurl] = useState();
  var heading = ["Offer", "Link", "Title"];
  var reader = new FileReader();
  useEffect(() => {
    setIsLoading(true);
    fetch("https://crazy-liveline-api.devserapp.com/offers")
      .then((res) => res.json())
      .then((res) => {
        setBody(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
    return () => {};
  }, []);
  if(offerImg){
    reader.readAsDataURL(offerImg);

    reader.onload = (e) => {
      setOfferurl(e.target.result);
    };
  }
  const compare = (x, y) => {
    //   console.log(x)
    //   console.log(y)
    return x.includes(y);
  };
  
  const submitHandler = (e) => {
    e.preventDefault();
    e.preventDefault();
    if (
      link?.trim() === "" ||
      link === undefined ||
      title === "" ||
      title === undefined
    ) {
      alert("Enter DATA!");
      return;
    }
    
    const offer_obj = new FormData();
    var data = {
      title: title,
      link: link,
      offerImage: offerImg,
    };
    for (const key in data) {
      offer_obj.append(key, data[key]);
    }

    fetch("https://crazy-liveline-api.devserapp.com/offers/add", {
      method: "POST",
      body: offer_obj,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        fetch("https://crazy-liveline-api.devserapp.com/offers")
      .then((res) => res.json())
      .then((res) => {
        setBody(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
      }).catch(err=>console.log(err));
  };
  console.log(isLoading);
  return (
    <div className="content-inner">
      {/* Page Header*/}
      <header className="page-header">
        <div className="container-fluid">
          <h2 className="no-margin-bottom">Manage Offers</h2>
        </div>
      </header>
      <section className="forms">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <form
                id="offer"
                name="offer"
                onSubmit={submitHandler}
                className="form-horizontal"
              >
                {/* <input
                      type="hidden"
                      name="_token"
                      defaultValue="OrPreV86Q1ZNT9FXnw2MCbQy5k5U7hnH8peQeYdj"
                    />{" "} */}
                <div className="form-group row">
                  <label className="col-sm-3 form-control-label">
                    Offer Title
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="name"
                      type="text"
                      name="Team Title"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="line" />
                <div className="form-group row">
                  <label className="col-sm-3 form-control-label">
                    Offer Link
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="name"
                      type="text"
                      name="Team Title"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        setLink(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* <div class="line"></div>
                                    <div class="form-group row">
                                        <label class="col-sm-3 form-control-label">Team Code</label>
                                        <div class="col-sm-9">
                                            <input id="code" type="number" name="code" value="" class="form-control" autocomplete="off">
                                        </div>
                                    </div> */}
                <div className="line" />
                <div className="form-group row">
                  <label className="col-sm-3 form-control-label">
                    Offer Image
                  </label>
                  <div className="col-sm-9">
                    <input
                      id="image"
                      type="file"
                      name="image"
                      className="form-control"
                      onChange={(e) => {
                        setOfferImg(e.target.files[0]);
                      }}
                    />
                  </div>
                  
                </div>
                {offerImg && (
                          <img
                            src={Offerurl}
                           
                            width="200em"
                            height="100px"
                          />
                        )}
                <div className="line"> </div>
                <div className="form-group row">
                  <div className="col-sm-4 offset-sm-3">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </form>

              {
                <div className="card">
                  <div className="card-body">
                    <div
                      id="data-table_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div className="dataTables_length" id="data-table_length">
                        <label>
                          Show{" "}
                          <select
                            name="data-table_length"
                            aria-controls="data-table"
                            className
                            onChange={(e) => {
                              console.log("changed");
                              setListVal(e.target.value);
                            }}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div id="data-table_filter" className="dataTables_filter">
                        <label>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search"
                            aria-controls="data-table"
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                // console.log(e.target.value.length)
                                const filtered = body_1?.filter((arr) => {
                                  return (
                                    compare(arr.title, e.target.value) ||
                                    compare(arr.link, e.target.value)
                                  );
                                });
                                setBody(filtered);
                              } else {
                                setBody(body_1);
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div
                        id="data-table_processing"
                        className="dataTables_processing"
                        style={{ display: "none" }}
                      >
                        <div className="loader">
                          <span className="loader-image">
                            <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                          </span>
                        </div>
                      </div>

                      {!isLoading && (
                        <Table
                          heading={heading}
                          body={body.slice(0, listVal).sort((x, y) => {
                            if (
                              x.title?.toLowerCase() <
                              y.title?.toLowerCase()
                            ) {
                              return -1;
                            }
                            return 1;
                          })}
                        />
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* {addNew && <><EditTeam onCancel={()=>{setAddNew(false);console.log("onCanel")}}/> 
                
                </>} */}
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};
export default Offers;
