import React, { Suspense, useEffect, useRef, useState } from "react";
import { Router, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "../../css/styles.css";
import "../../css/custom.css";
 import  '../../fontawesome-free-6.0.0-beta3-web/css/all.min.css'
import  '../../css/fontastic.css'
import "../../css/style.default.css";
import "../../css/styles.css";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import Submit from "../../Submit";
import Header from "../Header/index";
import Sidebar from "../Sidebar/index";
import Dashboard from "../Dashboard/index";
import ManageTeam from "../ManageTeam/index";
import ChangePassword from "../ChangePassword/index";
import ViewTeam from "../ViewTeam/index";
import EditTeam from "../EditTeam/index";
import ViewPlayer from "../ViewPlayer/index";
import ManagePlayer from "../ManagePlayer/index";
import EditPlayer from "../EditPlayer/index";
import ManageProfile from "../ManageProfile/index";
import AdvertiseWeb from "../AdvertiseWeb/index";
import AdvertiseMobile from "../AdvertiseMobile/index";
import Reset from "../Reset/index";
import Login from "../Login/index";
import Footer from "../Footer/index";
import Mergeapi from "../Merge-api/index";
import Offers from "../Offers";


function HomePage() {
  const history=useHistory()
  console.log(localStorage.getItem('token'))
  window.addEventListener("popstate", () => {
    if (
      window.localStorage.getItem("token") === "admin@rnews.com"
    ) {history.push('/home');return}
     console.log(window.localStorage.getItem('token'))
  });
  
  return (

    
    <React.Fragment>
      <Switch>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      

      <Route path="/home">
      {window.localStorage.getItem('token')==='null'&&<Redirect to='/login'/>}
        
      {window.localStorage.getItem('token')==='admin@rnews.com' &&
        <><Header />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />

          <Route path="/home" exact>
            <Dashboard />
          </Route>
          <Switch>
            <Route path='/home/changepassword'><ChangePassword/></Route>
            <Route path="/home/manageteam" exact>
              <ManageTeam />
            </Route>
            {/* <Route path="/home/manageteam/viewteam" exact>
              <ViewTeam />
            </Route> */}
            {/* <Route path="/home/manageteam/Add" exact>
              <EditTeam />
            </Route> */}
            {/* <Route path="/home/manageplayer/viewplayer" exact>
              <ViewPlayer />
            </Route>
            <Route path="/home/manageplayer" exact>
              <ManagePlayer />
            </Route>
            <Route path="/home/manageplayer/Add" exact>
              <EditPlayer/>
            </Route> */}
            <Route path="/home/manageprofile" exact>
              <ManageProfile />
            </Route>
            <Route path="/home/ads" exact>
              <Redirect to='/home/ads/mobile'/>
            </Route>
            {/* <Route path="/home/ads/web" exact>
              <AdvertiseWeb />
            </Route> */}
            <Route path="/home/ads/mobile" exact>
              <AdvertiseMobile />
            </Route>
            {/* <Route path="/home/mergeapi" exact>
              <Mergeapi />
            </Route> */}
            <Route path="/home/offers" exact>
              <Offers />
            </Route>
            
            <Route><Redirect to='/home'/></Route>
          </Switch>
         
          
        
        </div></>}
        
      </Route>
      
      <Route path="/resetpassword" exact>
        <Reset />
      </Route>
      <Route>
        <h1>Page not found!</h1>
      </Route>
      </Switch>
     
      </React.Fragment>

      
  );
}

export default HomePage;

//"https://firebasestorage.googleapis.com/images/_uploads_hashnode_Bugatti.jpg"
