import { Link } from "react-router-dom";

const TableRow = (props) => {

  return (
    <>
      {!props?.row?.country_code && !props?.row?.title && (
        <tr>
          <td>{props?.row?.key}</td>
          <td>{props?.row?.match}</td>
          <td>
            <Link
              to="/home/mergeapi"
              onClick={() => {
                console.log("req sent");
                fetch(
                  `https://rnews-api.devserapp.com/mergeapi/${props?.row?.key}`,
                  { method: "DELETE" }
                )
                  .then((res) => res.json())
                  .then((res) => {console.log(res);window.location.reload()})
                  .catch((err) => console.log(err));
              }}
              className="btn action-btn"
              title="Delete"
              role="button"
              aria-pressed="true"
            >
              <i className="fa fa-trash red" aria-hidden="true" />
            </Link>
          </td>
        </tr>
      )}
      {!props?.row?.country_code && !props?.row?.key && (
        <tr>
          <td>
            <img
              src={`https://crazy-liveline-api.devserapp.com/offers/${props?.row?.title.trim()}`}
              className="img-fluid rounded-circle"
            />
          </td>
          <td>{props?.row?.link}</td>
          <td>{props?.row?.title}</td>
          <td>
            <Link
              to="/home/offers"
              onClick={() => {
                console.log("req sent",`https://crazy-liveline-api.devserapp.com/offers/${props?.row?.title}`);
                fetch(`https://crazy-liveline-api.devserapp.com/offers/${props?.row?.title}`, {
                  method: "DELETE",
                })
                  .then((res) => res.json())
                  .then((res) => {console.log(res);window.location.reload()})
                  .catch((err) => console.log(err));
              }}
              className="btn action-btn"
              title="Delete"
              role="button"
              aria-pressed="true"
            >
              <i className="fa fa-trash red" aria-hidden="true" />
            </Link>
          </td>
        </tr>
      )}
      {props.row.player_name && (
        <tr>
          <td>
            <img
              src={`https://rnews-api.devserapp.com/getplayer/${
                props.row.country_code + "_" + props.row.player_code
              }`}
              className="img-fluid rounded-circle"
            />
          </td>
          <td>{props.row.player_name}</td>
          <td>{props.row.player_code}</td>
          <td>{props.row.team_name}</td>
          <td>{props.row.country_code}</td>
          <td>
            <>
              {" "}
              <Link
                to={{
                  pathname: "/home/manageplayer/viewplayer",
                  state: { player: props.row },
                }}
                href="view.html"
                className="btn action-btn"
                role="button"
                aria-pressed="true"
                title="View"
              >
                <i className="fa fa-eye green" aria-hidden="true" />
              </Link>
              <Link
                to={{
                  pathname: "/home/manageplayer/Add",
                  state: { player: props.row },
                }}
                href="edit.html"
                className="btn action-btn"
                role="button"
                aria-pressed="true"
                title="Edit"
              >
                <i className="fa fa-pencil  green" aria-hidden="true" />
              </Link>
              <Link
                to="/home/manageplayer"
                onClick={() => {
                  if (
                    window.confirm(
                      "This will delete all the data containing info of player!\nAre you sure want to delete?"
                    )
                  ) {
                    fetch(
                      `https://rnews-api.devserapp.com/deleteplayer/${props.row.team_name}/${props.row.player_code}`,
                      { method: "DELETE" }
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        console.log(res);
                        window.location.reload();
                      })
                      .catch((err) => console.log(err));
                  }
                }}
                className="btn action-btn"
                title="Delete"
                role="button"
                aria-pressed="true"
              >
                <i className="fa fa-trash red" aria-hidden="true" />
              </Link>
            </>
          </td>
        </tr>
      )}
      {!props.row.player_code && props?.row?.country_code && (
        <tr>
          <td>
            <img
              src={`https://rnews-api.devserapp.com/getteam/${props.row.country_code}`}
              className="img-fluid rounded-circle"
            />
          </td>
          <td>{props.row.team_name}</td>
          <td>{props.row.country_code}</td>
          <td>
            <>
              {" "}
              <Link
                to={{
                  pathname: "/home/manageteam/viewteam",
                  state: { team: props.row },
                }}
                className="btn action-btn"
                role="button"
                aria-pressed="true"
                title="View"
              >
                <i className="fa fa-eye green" aria-hidden="true" />
              </Link>
              <Link
                to={{
                  pathname: "/home/manageteam/Add",
                  state: { team: props.row },
                }}
                className="btn action-btn"
                role="button"
                aria-pressed="true"
                title="Edit"
              >
                <i className="fa fa-pencil  green" aria-hidden="true" />
              </Link>
              <Link
                to="/home/manageteam"
                onClick={() => {
                  console.log("req sent");
                  if (
                    window.confirm(
                      "This will delete all the data containing info of players of team!\nAre you sure want to delete?"
                    )
                  ) {
                    fetch(
                      `https://rnews-api.devserapp.com/deleteteam/${props.row.country_code}`,
                      { method: "DELETE" }
                    )
                      .then((res) => res.json())
                      .then((res) => {
                        console.log(res);
                        window.location.reload();
                      })
                      .catch((err) => console.log(err));
                  }
                }}
                className="btn action-btn"
                title="Delete"
                role="button"
                aria-pressed="true"
              >
                <i className="fa fa-trash red" aria-hidden="true" />
              </Link>
            </>
          </td>
        </tr>
      )}
    </>
  );
};
export default TableRow;
