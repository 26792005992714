import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import UserAdmin from '../../assets/img/user.png';
import '../../css/custom.css';
import '../../css/style.default.css';
import '../../css/styles.css';
// import '../../css/font-awesome.min.css';
function Sidebar() {
    const location=useLocation()
    const [show,setShow]=useState()
    useEffect(()=>{
        if(location.state){
        setShow(location.state.menubtn)
        }
    },[location])
    return (
                <nav className={show?'side-navbar':"side-navbar shrinked"}>
                        {/* Sidebar Header*/}
                        <div className="sidebar-header d-flex align-items-center">
                        <div className="avatar">
                            <img src={UserAdmin} className="img-fluid rounded-circle" />
                        </div>
                        <div className="title">
                            <h1 className="h4">Admin</h1>
                        </div>
                        </div>
                        {/* Sidebar Navidation Menus*/}
                        <ul className="list-unstyled">
                        <li>
                            <Link to='/home'>
                            <i className="fa fa-home" />Home
                            </Link>
                        </li>
                        <li className=" ">
                            <Link to="/home/manageprofile">
                            <i className="fas fa-user-shield"/>Admin Users
                            </Link>
                        </li>
                        {/* <li className=" ">
                            <Link to='/home/manageteam'>
                            <i className="fa fa-users" />Team
                            </Link>
                        </li> 
                        <li className=" ">
                            <Link to='/home/manageplayer'>
                            <i className="fas fa-user-friends"/>Player
                            </Link>
                        </li>  */}
                        <li className=" ">
                            <Link to="/home/ads">
                            <i className="fas fa-ad"/>Advertise
                            </Link>
                        </li> 
                        {/* <li className=" ">
                            <Link to="/home/mergeapi">
                            <i class="fas fa-object-group"/>Merge Api
                            </Link>
                        </li>  */}
                        <li className=" ">
                            <Link to="/home/offers">
                            <i class="fa-solid fa-badge-percent"/>Offers
                            </Link>
                        </li> 
                        </ul>
                </nav>
  
        );
}

export default Sidebar;